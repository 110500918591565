import React from 'react';
import './FloatingButton.css'; // Importing the CSS file for styling

const handleClick = () => {
  // Navigate to the specified URL
  window.location.href = 'https://artificialgrassrecycle.com/turf_qualifier/';
};

const FloatingButton = () => {
  return (
    <button className="floating-button" alt="map link" title="Let's Begin" onClick={handleClick}>
    Let's Begin Tap here 
  </button>
  );
};

export default FloatingButton;
