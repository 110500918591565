import React, { useState } from 'react';
import './BurgerMenu.css';

function BurgerMenu({ onClick }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
    onClick(); // Trigger the parent's handler
  };

  return (
    <div className={`burger-menu ${isOpen ? 'open' : ''}`} onClick={handleClick}>
      <div className="burger-line"></div>
      <div className="burger-line"></div>
      <div className="burger-line"></div>
    </div>
  );
}

export default BurgerMenu;
