import React, { useState } from 'react';
import './Footer.css';

function Footer() {
  // State for toggling the input area visibility
  const [isInputOpen, setInputOpen] = useState(false);

  // State for storing the messages
  const [messages, setMessages] = useState([]);

  // Function to toggle the visibility of the input area
  const toggleInput = () => {
    setInputOpen(!isInputOpen);
  };

  // Function to handle sending of messages
  const handleSendMessage = async (e) => {
    e.preventDefault();
    const message = e.target.elements.message.value.trim();
    if (message) {
      // Add user message to messages state
      const userMessage = { id: messages.length + 1, text: message, sender: 'user' };
      setMessages(messages => [...messages, userMessage]);
      
      // Clear the input field
      e.target.elements.message.value = '';

      try {
        // Replace with your API call to ChatGPT-4
        const aiResponse = await fetch('Your-API-Endpoint', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // Any required headers like authorization
          },
          body: JSON.stringify({ message: message })
        }).then(response => response.json());

        // Add AI response to messages state
        const aiMessage = { id: messages.length + 2, text: aiResponse.text, sender: 'ai' };
        setMessages(messages => [...messages, aiMessage]);
      } catch (error) {
        console.error('Error communicating with the AI:', error);
        // Handle error appropriately
      }
    }
  };

  return (
    <div className="footer">
      {/* Button to toggle the AI assistant */}
      <button className="ai-button" onClick={toggleInput}>Call Toll Free 844.444.0469</button>

      {/* Conditional rendering of the message box */}
      {isInputOpen && (
        <div className="message-box">
          {/* Displaying the messages */}
          <div className="messages">
            {messages.map(msg => (
              <p key={msg.id}>{msg.text}</p>
            ))}
          </div>

          {/* Form for sending a message */}
          <form onSubmit={handleSendMessage}>
            <input type="text" name="message" placeholder="Ai support coming soon" />
            <button type="submit">Send</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default Footer;
