import React, { } from 'react';
import './App.css';
import Header from './Header';
import FloatingButton from './FloatingButton';
import Footer from './Footer';
import TawkToChat from './TawkToChat';


function App() {

  /*
  // Updated initial state to include the new question
  const [answers, setAnswers] = useState({ projectType: '', zipCode: '' });
  const [currentQuestion, setCurrentQuestion] = useState(1);

  const handleInputChange = (e) => {
    setAnswers({ ...answers, [e.target.name]: e.target.value });
  };

  const handleNext = () => {
    setCurrentQuestion(currentQuestion + 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitted Answers:', answers);
    // Add your submission logic here
  };
*/ /* this is for the form I deleted on app.js original online has this code connected to a form  */    
  return (
    <div className="App">
       <video autoPlay loop muted playsInline className="background-video">
        <source src="/face.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Header /> 

      <FloatingButton />
      <TawkToChat /> 
      
      <Footer />
    </div>
  );
}

export default App;
